declare global {
  interface Window {
    jQuery: JQueryStatic;
    $: JQueryStatic;
  }
}

window.jQuery = window.$ = $
import 'bootstrap/dist/js/bootstrap'

import './../smoothscroll'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Routes from 'routes'

import { Flash } from '../Flash'
import FlashType from './../FlashType'

$(document).on('input', 'textarea', function () {
  const lines = String($(this).val())
  const linesCount = (lines + '\n').match(/\n/g).length

  // textareaだと認識できる高さ以上になった場合に自動で高さを変更（下限をrows=5に設定）
  $(this).attr('rows', (linesCount > 5) ? linesCount : 5)
})

window.addEventListener('DOMContentLoaded', () => {
  // ページが読み込まれれた時に、改行コード分のrowsを設定
  $('textarea').each(function() {
    const lines = $(this).val().toString()
    const linesCount = (lines + '\n').match(/\n/g).length
    $(this).attr('rows', (linesCount > 5) ? linesCount : 5)
  })
})

$(function() {
  $(document).on('click', '.toggle-btn', (e) => { $(e.target).toggleClass('is-active') })
  $(document).on('click', '.navbar-toggle', () => { $('body').toggleClass('is-fixed') })
  $(document).on('change', 'select.js-url-select', (event) => { window.location.href = $(event.target).find('option:checked').data('url') as string })
})

window.addEventListener('DOMContentLoaded', () => {
  void $.ajax({
    type: 'GET', url: Routes.session_path(), dataType: 'json',
    success: json => { if (json.user && json.user.id) document.body.classList.add('logged_in') },
  })

  const flashPlaceholder: HTMLElement = document.querySelector('[data-react-flash]')
  if (flashPlaceholder) {
    const props: FlashType = JSON.parse(flashPlaceholder.dataset.reactFlash) as FlashType
    ReactDOM.render(<Flash flash={props} />, flashPlaceholder)
  }
})
